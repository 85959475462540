import { takeEvery, takeLatest, put, call, select } from 'redux-saga/effects';
import cookie from 'react-cookie';
import moment from 'moment-timezone';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import { parse } from 'query-string';
import { push } from 'connected-react-router';

import AccountApi from 'lib/api/checkout/account';
import ClientApi from 'lib/api/client';
import UserApi from 'lib/api/user';
import env from 'lib/env';
import { isValidRedirectURI } from 'lib/common/url-helpers';
import {
  enterpriseInviteSuccess,
  enterpriseInviteError,
  passwordResetError,
  passwordResetSuccess,
  passwordRecoveryError,
  passwordRecoverySuccess,
  userUpdateError,
  userUpdateSuccess,
  vehicleSaveError,
  vehicleSaveSuccess,
  vehicleDeleteError,
} from 'lib/common/messages';

import addMessage from 'action-creators/messaging/add-message';
import addMessageAndScrollToTop from 'action-creators/messaging/add-message-and-scroll-to-top';
import addErrors from 'action-creators/account/add-errors';
import gotSessionCreator, { GOT_SESSION } from 'action-creators/account/got-session';
import { SIGN_IN } from 'action-creators/account/sign-in';
import { SIGN_UP } from 'action-creators/account/sign-up';
import { RESET_PASSWORD } from 'action-creators/account/reset-password';
import { RECOVER_PASSWORD } from 'action-creators/account/recover-password';
import { UPDATE_USER } from 'action-creators/account/update-user';
import userUpdated from 'action-creators/account/user-updated';
import { ACTIVATE_ACCOUNT } from 'action-creators/account/activate-account';
import { ACTIVATE_ACCOUNT_MODAL } from 'action-creators/bookings/activate-account-modal';
import { CHANGE_USER_INFO } from 'action-creators/account/change-user-info';
import { SAVE_NEW_VEHICLE } from 'action-creators/vehicles/save-new-vehicle';
import savedVehicle from 'action-creators/vehicles/saved-vehicle';
import deletedVehicle from 'action-creators/vehicles/deleted-vehicle';
import { DELETE_VEHICLE } from 'action-creators/vehicles/delete-vehicle';
import { SET_DEFAULT_VEHICLE } from 'action-creators/vehicles/set-default-vehicle';
import { UPDATE_VEHICLE } from 'action-creators/vehicles/update-vehicle';
import { HANDLE_ENTERPRISE_INVITE } from 'action-creators/account/handle-enterprise-invite';
import gotVehicles from 'action-creators/account/got-vehicles';
import getBookingCounts from 'action-creators/bookings/get-booking-counts';
import associate from 'action-creators/analytics/associate';
import cleanupEnterpriseInvite from 'action-creators/account/cleanup-enterprise-invite';

const getUser = state => state.account.user;
const getEnterpriseInvite = state => state.account.enterpriseInvite;
const getBrand = state => state.brand.brand;
const getRequestQueue = state => state.requests.requestQueue;
const getRouterLocation = state => state.router.location;
const getAccountActivated = state => state.account.accountActivated;
const getActivateAccountModalDismissed = state => state.bookings.activateAccountModalDismissed;

const { COOKIE_DOMAIN, SECURE_COOKIES } = env();

const resetPasswordFromAPI = ({ email, accessToken, requestQueue }) => (
  ClientApi
    .resetPassword({ email }, accessToken, requestQueue)
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }))
);

export function* resetPassword(action) {
  const { token: accessToken } = yield select(getUser);
  const { email } = action.payload;
  const requestQueue = yield select(getRequestQueue);
  const { error } = yield call(resetPasswordFromAPI, { email, accessToken, requestQueue });
  if (error) {
    yield put(addMessage(passwordResetError));
  } else {
    yield put(addMessage(passwordResetSuccess));
  }
  // yield put({ type: SCROLL_TO_TOP });
}

const postPasswordRecovery = ({ email, password, passwordResetToken, accessToken, requestQueue }) => (
  ClientApi
    .recoverPassword({ email, password, passwordResetToken, returnSession: true }, accessToken, requestQueue)
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }))
);

export function* recoverPassword(action) {
  const { email, password, passwordResetToken } = action.payload;
  const user = yield select(getUser);
  const accessToken = user.token;
  const requestQueue = yield select(getRequestQueue);

  const { body, error } = yield call(postPasswordRecovery, {
    email,
    password,
    passwordResetToken,
    accessToken,
    requestQueue,
  });

  if (error) {
    yield put(addMessage(passwordRecoveryError));
  } else {
    yield put(gotSessionCreator({ sessionId: body.id, token: body.token, user: body.user, type: 'stats', query: 'upcoming' }));
    yield call(UserApi.setSession, { requestQueue });
    yield put(addMessage(passwordRecoverySuccess));
    yield put(push('/account/'));
  }
}

const createSession = ({ credentialType, credentials, requestQueue, accessToken }) => (
  UserApi
    .createSession({ credentialType, credentials, requestQueue, accessToken })
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }))
);

const putUserUpdate = ({ userData, accessToken, requestQueue }) => (
  ClientApi
    .updateUser(userData, accessToken, requestQueue)
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }))
);

export function* signIn(action) {
  const { token: accessToken } = yield select(getUser);
  /* eslint-disable camelcase */
  const {
    credentialType = 'password',
    email,
    password,
    google_id_token,
    first_name,
    last_name,
  } = action.payload;
  /* eslint-enable camelcase */

  let { marketingAllowed } = action.payload;
  const requestQueue = yield select(getRequestQueue);

  const { body, error } = yield call(createSession, {
    credentialType,
    credentials: {
      email,
      password,
      google_id_token,
      first_name,
      last_name,
    },
    requestQueue,
    accessToken,
  });

  if (error) {
    const { status } = error;

    if (status === 404) {
      yield put(addErrors({ signInEmail: 'This account does not exist' }));
    } else {
      yield put(addErrors({ password: 'Your password is incorrect' }));
    }
    return;
  }

  if (localStorage.getItem('signInMethod')) {
    // clear any previous signInMethod values stored in user's localStorage
    localStorage.removeItem('signInMethod');
  }

  if (credentialType === 'google' || credentialType === 'password') {
    localStorage.setItem('signInMethod', credentialType);
  }
  yield put(gotSessionCreator({ sessionId: body.id, token: body.token, user: body.user, type: 'stats', query: 'upcoming' }));
  yield call(UserApi.setSession, { requestQueue });

  // Because users can sign up on the sign IN page where there is no checkbox
  // and because we don't have a flag to say they are newly created
  // we need to check creation times, and fall back to country based defaults
  const createdAt = moment(get(body, 'user.created_at'));
  if (moment().diff(createdAt, 'seconds') < 90) {
    const brand = yield select(getBrand);
    if (isUndefined(marketingAllowed)) {
      marketingAllowed = !brand.requireMarketingOptIn;
    } else if (!brand.requireMarketingOptIn) {
      marketingAllowed = true;
    }

    yield call(putUserUpdate, {
      userData: { marketing_allowed: marketingAllowed },
      accessToken: body.token,
      requestQueue,
    });
  }

  yield call(handleSendEnterpriseInvite, { userId: body.user.id, requestQueue });

  let { redirectURI } = action.payload;
  redirectURI = decodeURIComponent(redirectURI);

  const routerLocation = yield select(getRouterLocation);
  if (!redirectURI || redirectURI.match(/^\/account\/sign/)) {
    const query = parse(routerLocation.search);
    redirectURI = decodeURIComponent(query.redirect_uri);
  }

  if (!isValidRedirectURI(redirectURI)) {
    redirectURI = '/account/';
  }

  window.location.href = redirectURI;
}

const cookieSession = ({ user, sessionId }) => {
  const { token } = user;
  if (!user || !sessionId) { return; }

  const expires = moment().add(5, 'years').toDate();
  const secure = SECURE_COOKIES === 'true';
  const remembercs = user.api_key || user.remembercs;

  const options = { domain: COOKIE_DOMAIN, expires, path: '/', secure };

  cookie.save('rememberme', user.id, options);
  cookie.save('remembercs', remembercs, options);
  cookie.save('SID', sessionId, { domain: COOKIE_DOMAIN, path: '/', secure });
  cookie.save('token', token, options);

  if (user.accountActivated) {
    cookieActivateUser();
  }
};

const getVehicles = ({ accessToken, requestQueue }) => (
  AccountApi
    .getVehicles(accessToken, requestQueue)
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }))
);

export function* gotSession(action) {
  yield call(cookieSession, action.payload);
  const user = yield select(getUser);
  if (user.accountActivated) { yield put({ type: ACTIVATE_ACCOUNT }); }
  const { token: accessToken } = user;
  const requestQueue = yield select(getRequestQueue);
  const { body, error } = yield call(getVehicles, { accessToken, requestQueue });
  if (!error) {
    yield put(gotVehicles(body));
  }
  yield put(getBookingCounts({ query: ['upcoming'], type: ['bookings'] }));
  yield put(associate());
}

const createUser = ({ email, password, firstName, lastName, marketingAllowed, requestQueue, accessToken }) => (
  ClientApi
    .createUser({ email, password, firstName, lastName, marketingAllowed }, requestQueue, accessToken)
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }))
);

const acceptEnterpriseInvite = ({ authToken, userId, requestQueue }) => (
  ClientApi
    .acceptEnterpriseInvite(authToken, userId, requestQueue)
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }))
);

export function* handleEnterpriseInvite() {
  const user = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  if (!user) {
    return;
  }

  yield call(handleSendEnterpriseInvite, { userId: user.id, requestQueue });
}

export function* handleSendEnterpriseInvite({ userId, requestQueue }) {
  const enterpriseInvite = yield select(getEnterpriseInvite);
  if (!enterpriseInvite) { return; }

  const authToken = enterpriseInvite.get('authToken');
  const { error } = yield call(acceptEnterpriseInvite, { authToken, userId, requestQueue });

  yield put(cleanupEnterpriseInvite());

  if (error) {
    yield put(addMessage(enterpriseInviteError));
  } else {
    yield put(addMessage(enterpriseInviteSuccess));
  }
}

function* handleAccountExists({ code, email }) {
  if (code === 'account_exists') {
    const accountActivated = yield select(getAccountActivated);
    if (!accountActivated) {
      const activateAccountModalDismissed = yield select(getActivateAccountModalDismissed);
      if (!activateAccountModalDismissed) {
        yield put({ type: CHANGE_USER_INFO, payload: { email } });
        yield put({ type: ACTIVATE_ACCOUNT_MODAL });
      }
    }
  }
}

export function* signUp(action) {
  const { token: accessToken } = yield select(getUser);
  const brand = yield select(getBrand);
  const { email, password, firstName, lastName } = action.payload;
  let { marketingAllowed } = action.payload;

  if (!brand.requireMarketingOptIn) { marketingAllowed = true; }

  const requestQueue = yield select(getRequestQueue);

  const { body, error } = yield call(createUser, {
    email,
    password,
    firstName,
    lastName,
    marketingAllowed,
    requestQueue,
    accessToken,
  });

  if (error) {
    const errors = {};
    const { code } = error.response.body;
    yield call(handleAccountExists, { code, email });
    const { details } = error.response.body;
    Object.keys(details).forEach((field) => {
      errors[field] = `${startCase(field)} ${details[field].messages[0]}`;
    });

    yield put(addErrors({ errors }));
    return;
  }

  const session = get(body, ['_embedded', 'pw:session']);
  if (session) {
    yield put(gotSessionCreator({
      user: session.user,
      token: session.token,
      sessionId: session.id,
    }));
    yield call(UserApi.setSession, { requestQueue });
  }

  yield call(handleSendEnterpriseInvite, { userId: session.user.id, requestQueue });

  let { redirectURI } = decodeURIComponent(action.payload);

  if (!redirectURI || redirectURI.match(/^\/account\/sign/)) {
    const routerLocation = yield select(getRouterLocation);
    const query = parse(routerLocation.search);
    redirectURI = decodeURIComponent(query.redirect_uri);
  }

  if (!isValidRedirectURI(redirectURI)) {
    redirectURI = '/account/';
  }
  
  window.location.href = redirectURI;
}

function cookieActivateUser() {
  cookie.save('account-activated', true, { path: '/', expires: moment().add(5, 'years').toDate(), domain: COOKIE_DOMAIN });
}

export function updateSession({ token, requestQueue }) {
  const sessionId = cookie.load('SID');
  return UserApi.refreshSession({ token, sessionId, requestQueue })
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }));
}

export function* updateUser(action) {
  const {
    firstname,
    lastname,
    email,
    phone,
    password,
    passwordChanged,
    feedbackReminder,
  } = action.payload;

  const userData = {
    first_name: firstname,
    last_name: lastname,
    email,
    phone_number: phone,
    feedback_reminder: feedbackReminder,
  };

  if (passwordChanged) {
    userData.password = password;
  }

  let user = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  const { token: accessToken } = user;

  user = user.merge({ firstname, lastname, email });
  const errors = user.validateFields();

  if (phone && ![0, 10, 11].includes(phone.length)) {
    errors.password = {
      isValid: false,
      isEmpty: false,
      displayName: 'phone',
    };
  }

  if (passwordChanged) {
    if (!password) {
      errors.password = {
        isValid: false,
        isEmpty: true,
        displayName: 'password',
      };
    } else if (password.length < 8) {
      errors.password = {
        isValid: false,
        isEmpty: false,
        displayName: 'password',
      };
    }
  }


  const isValid = Object.keys(errors).reduce((v, f) => v && get(errors, [f, 'isValid'], false), true);
  if (!isValid) {
    yield put(addErrors(errors));
    return;
  }

  const { body, error } = yield call(putUserUpdate, { userData, accessToken, requestQueue });

  if (error) {
    yield put(addMessageAndScrollToTop(userUpdateError));
  } else {
    yield put(userUpdated(body));
    const { body: sessionBody } = yield call(updateSession, { token: accessToken, requestQueue });
    if (sessionBody) {
      yield put(gotSessionCreator(sessionBody));
    }
    yield put(addMessageAndScrollToTop(userUpdateSuccess));
  }
}

function* activateUser() {
  yield call(cookieActivateUser);
}

function* setDefaultVehicle(action) {
  const { defaultVehicleId } = action.payload;
  const { token: accessToken } = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  const { error } = yield call(setDefaultVehicleFromAPI, { defaultVehicleId, accessToken, requestQueue });
  if (error) {
    yield put(addMessage(vehicleSaveError));
  }
}

function setDefaultVehicleFromAPI({ defaultVehicleId, accessToken, requestQueue }) {
  return AccountApi
    .setDefaultVehicle({ defaultVehicleId, accessToken, requestQueue })
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }));
}

function* deleteVehicle(action) {
  const { vehicleId } = action.payload;
  const { token: accessToken } = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  const { error } = yield call(deleteVehicleFromAPI, { vehicleId, accessToken, requestQueue });
  if (error) {
    yield put(addMessage(vehicleDeleteError));
  } else {
    yield put(deletedVehicle({ vehicleId }));
  }
}

function deleteVehicleFromAPI({ vehicleId, accessToken, requestQueue }) {
  return AccountApi
    .deleteVehicle({ vehicleId, accessToken, requestQueue })
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }));
}

export function* saveVehicle(action) {
  const { vehicle } = action.payload;
  const { token: accessToken } = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  let error;
  let body;
  if (vehicle.id) {
    ({ body, error } = yield call(updateVehicleFromAPI, { vehicle, accessToken, requestQueue }));
  } else {
    ({ body, error } = yield call(saveVehicleFromAPI, { vehicle, accessToken, requestQueue }));
  }
  if (error) {
    yield put(addMessage(vehicleSaveError));
  } else {
    yield put(savedVehicle({ body }));
    yield put(addMessage(vehicleSaveSuccess));
    yield put(push('/account/vehicles/'));
  }
}

function saveVehicleFromAPI({ vehicle, accessToken, requestQueue }) {
  return AccountApi
    .saveVehicle({ vehicle, accessToken, requestQueue })
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }));
}

function updateVehicleFromAPI({ vehicle, accessToken, requestQueue }) {
  return AccountApi
    .updateVehicle({ vehicle, accessToken, requestQueue })
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }));
}

export default function* root() {
  yield takeEvery(GOT_SESSION, gotSession);
  yield takeLatest(RESET_PASSWORD, resetPassword);
  yield takeLatest(SIGN_IN, signIn);
  yield takeLatest(SIGN_UP, signUp);
  yield takeLatest(RECOVER_PASSWORD, recoverPassword);
  yield takeLatest(UPDATE_USER, updateUser);
  yield takeEvery(GOT_SESSION, activateUser);
  yield takeLatest(ACTIVATE_ACCOUNT, activateUser);
  yield takeLatest(SET_DEFAULT_VEHICLE, setDefaultVehicle);
  yield takeLatest(DELETE_VEHICLE, deleteVehicle);
  yield takeLatest(SAVE_NEW_VEHICLE, saveVehicle);
  yield takeLatest(UPDATE_VEHICLE, saveVehicle);
  yield takeLatest(HANDLE_ENTERPRISE_INVITE, handleEnterpriseInvite);
}
